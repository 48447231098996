import Image from "next/image";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { registerModalShow, continueGuestModalShow } from "../../redux/counterSlice";

const IndexHero = () => {
  const dispatch = useDispatch();
  return (
    <>
      {/* <!-- Hero --> */}
      <div className="relative pb-8 sm:pb-24 md:pb-20 pt-28 sm:pt-32 md:pt-40 px-8 md:px-16 ">
        <div className="mx-auto max-w-2xl pt-0 text-center mb-12">
          <h1 className="mb-8 font-display text-3xl sm:text-5xl xl:text-[48px] text-jacarta-700 dark:text-white md:text-5xl">
            Challenge the ancients in a strategic game of cards
          </h1>
          <span className="dark:text-h2-accent text-lg sm:text-xl">
            A casual browser-based CCG you can play at your own pace and on any device. No downloads, no timers, no blockchain.
          </span>
        </div>
        <div className="space-x-12 text-center">
          <button
            onClick={() => dispatch(registerModalShow())}
            className="bg-accent shadow-accent-volume w-48 hover:bg-accent-dark w-48 rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
          >
            Sign Up
          </button>
          <button
            onClick={() => dispatch(continueGuestModalShow())}
            className="homeDiscord inline-block text-accent shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume w-48 rounded-full bg-white py-3 px-8 text-center font-semibold transition-all hover:text-white"
          >
            Play as Guest
          </button>
        </div>
      </div>
      {/* <!-- end hero --> */}
    </>
  );
};

export default IndexHero;
