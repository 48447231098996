import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, EffectCoverflow, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { coverflow_data } from "../../data/coverflow_data";
import Link from "next/link";
import Image from "next/image";

const CoverflowCarousel = ({ pantheons }) => {
  SwiperCore.use([Autoplay]);
  return (
    <>
      {/* <!-- Coverflow Slider --> */}
      { pantheons.length ? (
        <div className="relative px-0 pb-0 sm:px-0">
          {/* <!-- Slider --> */}
          <Swiper
            breakpoints={{
              // when window width is >= 640px
              100: {
                // width: 640,
                slidesPerView: 1,
                coverflowEffect:{
                  rotate: 15,
                  stretch: 90,
                  depth: 100,
                  modifier: 1,
                  scale: 0.6,
                  slideShadows: true,
                },
              },
              575: {
                // width: 640,
                slidesPerView: 5,
                coverflowEffect:{
                  rotate: 20,
                  stretch: 20,
                  depth: 100,
                  modifier: 1,
                  scale: 0.98,
                  slideShadows: true,
                },
              },
              // when window width is >= 768px
              992: {
                // width: 768,
                slidesPerView: 5,
                coverflowEffect:{
                  rotate: 20,
                  stretch: 40,
                  depth: 120,
                  modifier: 1,
                  scale: 0.96,
                  slideShadows: true,
                },
              },
              1200: {
                // width: 768,
                slidesPerView: 5,
                coverflowEffect:{
                  rotate: 18,
                  stretch: 30,
                  depth: 120,
                  modifier: 1,
                  scale: 0.96,
                  slideShadows: true,
                },
              },
            }}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={1}
            pagination={false}
            loop={true}
            coverflowEffect={{
              rotate: 24,
              stretch: 100,
              depth: 100,
              modifier: 1,
              scale: 0.96,
              slideShadows: true,
            }}
            autoplay={{ delay: 2000, disableOnInteraction: true }}
            speed={600}
            modules={[EffectCoverflow, Navigation, Autoplay]}
            navigation={{
              nextEl: ".swiper-button-next-4",
              prevEl: ".swiper-button-prev-4",
            }}
            className="swiper coverflow-slider !py-6 hideDots"
          >
            {pantheons && pantheons.map((item) => {
              const { card, pk, name } = item;
              return (
                <SwiperSlide key={pk}>
                  <article>
                    <div className="block overflow-hidden shadow-md transition-all md:hover:shadow-lg bg-transparent sm:bg-darker border-0 border-transparent md:hover:border-4 md:hover:border-accent md:hover:scale-105 px-6 sm:px-0">
                      <figure className="relative">
                        <Link href={"/cards?patheon=" + pk}>
                          <Image
                            width={381}
                            height={532}
                            src={card}
                            alt={name}
                            className="swiper-lazy w-full object-cover"
                          />
                        </Link>
                      </figure>
                      {/* <div className="p-6">
                        <div className="flex">
                          <div>
                            <Link href={"/cards?patheon=" + pk} className="block">
                              <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
                                {name}
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </article>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="swiper-button-prev-4 absolute top-1/2 left-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
            <Image
              width={15}
              height={15}
              src="/images/icons/prev.png"
              alt="prev"
              className=""
            />
          </div>
          <div className="swiper-button-next-4 absolute top-1/2 right-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
            <Image
              width={15}
              height={15}
              src="/images/icons/next.png"
              alt="next"
              className=""
            />
          </div>

          {/* <!-- end coverflow slider --> */}
        </div>
      ) : null }
    </>
  );
};

export default CoverflowCarousel;
