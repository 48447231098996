import Image from "next/image";
import FeaturesItem from "../../components/features-section/features-item.jsx";

const featureData = [
  {
    title: "BUILD YOUR DECK",
    body: "Combine cards from various types, rarities, and pantheons to unlock powerful synergies that can turn the tide of any match. \n\nWhether you favor the cunning tactics of Greek gods, the brute strength of Norse beasts, or the mystical enchantments of Egyptian artifacts, your deck can encompass the essence of multiple mythologies.",
    image: "01.webp",
  },
  {
    title: "CLIMB THE RANKINGS",
    body: "Play against real builds and decks of other players. See what they came up with, and counter them. There are no timers whatsoever, so play at your own pace! \n\nClimb from Recruit to Infiniti. Rise through the ranks based on battle points and the rare gems within your arsenal.",
    image: "02.webp",
  },
  {
    title: "FIND CARDS BY DIGGING SITES",
    body: "Collect cards and resources effortlessly by idling at ancient excavation sites or take a more active approach by opening packs with the Chronos you earn from winning matches against the decks of other players.",
    image: "03.webp",
  },
  {
    title: "BUY & SELL CARDS ON THE MARKET",
    body: "Maximize your deck's potential on the Marketplace: sell duplicates and acquire key cards. \n\nA seamless way for players to exchange cards through a fair auction-style system, ensuring you always have access to the cards you need.",
    image: "04.webp",
  },
];

const FeaturesSection = () => {
  let featureCount = 0;
  return (
    <section className="relative px-6 pt-8 pb-16 sm:p-16">
      <div className="m-auto md:max-w-[1200px] lg:border lg:border-accent-darker flex flex-col justify-stretch lg:rounded-s gap-y-16 lg:gap-y-0">
        {featureData.map((item) => {
          featureCount++;
          return (<FeaturesItem key={featureCount} featureCount={featureCount} featureData={item} />);
        })}
      </div>
    </section>
  );
};

export default FeaturesSection;
