import Image from "next/image";

const FeaturesItem = ({ featureCount, featureData }) => {
  return (
    <div className={`lg:p-10 lg:border-b border-accent-darker flex flex-col ${featureCount % 2 ? "lg:flex-row" : "lg:flex-row-reverse"} gap-10 align-top justify-items-start justify-start items-start relative whitespace-break-spaces`}>
      {featureCount && featureCount > 1 ? (<div className="h-[1px] mb-2 w-full bg-accent-dark lg:hidden"></div>) : null}
      <div className="flex flex-col gap-6 lg:gap-4 font-body text-[1.2rem] lg:max-w-[280px] xl:max-w-[390px]">
        {featureCount && featureCount > 0 ?
          (<div className="lg:gap-4 hidden lg:flex lg:flex-row">
            <div>
              {featureCount.toString()}
            </div>
            <div className="h-[1px] w-full bg-jacarta-300 my-auto"></div>
          </div>) : null
        }
        <div className="uppercase text-display text-2xl xl:text-3xl text-center lg:text-left font-semibold mb-2">
          {featureData?.title}
        </div>
        <div className="grow text-h2-accent">
          {featureData?.body}
        </div>
      </div>
      {featureData && featureData.image && featureData.image.length ?
        (
          <figure className="">
            <Image
              width={1027}
              height={629}
              src={`/images/${featureData.image}`}
              className="object-contain rounded-lg lg:rounded-2xl"
              alt={featureData?.title}
            />
          </figure>
        ) : null
      }

    </div>
  );
};

export default FeaturesItem;
