import Image from "next/image";
import React from "react";
import { useDispatch } from "react-redux";
import { registerModalShow } from "../../redux/counterSlice";

const Download = () => {
  const dispatch = useDispatch();
  return (
    <>
      {/* <!-- CTA Download App --> */}
      <div className="relative lg:mt-48">
        <div className="absolute inset-0 opacity-20 pointer-events-none overflow-hidden">
          <Image
            width={1920}
            height={682}
            src="/images/home/join_us_bg.webp"
            alt="Background"
            className="w-full h-full object-cover object-center"
          />
        </div>
        <div className="px-6 pt-8 lg:p-0 relative max-w-[1536px] mx-auto flex flex-col gap-x-8 justify-between align-center lg:flex-row">
          <div className="lg:px-8 flex flex-col justify-center lg:w-2/4 my-16 lg:my-40 text-center space-y-8 z-1">
            <h2 className="font-display text-xl text-jacarta-700 dark:text-white">
              Create Your Account<span className="text-jacarta-300">&nbsp;&nbsp;to join the fray and challenge the ancients at a game of Chronogram.</span>
            </h2>
            <div className="flex flex-row space-x-6 justify-center">
              <button
                onClick={() => dispatch(registerModalShow())}
                className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-10 text-center font-semibold text-white transition-all"
              >
                Sign Up
              </button>
              <a
                href="https://discord.gg/EKa9zwrehc"
                target="_blank"
                className="flex items-center rounded-full bg-white py-3 px-6 text-center font-semibold text-jacarta-500 shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
              >
                Join Discord
              </a>
            </div>
          </div>
          <div className="lg:w-[68%] flex items-center justify-center">
            <Image
              width={1920}
              height={1352}
              src="/images/home/join_us_npc.webp"
              className="object-contain object-bottom w-full lg:h-[200%] inline-block self-end lg:-mt-48"
              alt="mobile app"
            />
          </div>
        </div>
      </div>
      {/* <!-- end cta download app --> */}
    </>
  );
};

export default Download;
