import React, { useEffect, useState } from "react";
import { ReactSession } from 'react-client-session';
import Image from "next/image";
import Meta from "../../components/Meta";
import IndexHero from "../../components/hero/indexHero.jsx";
import FeaturesSection from "../../components/features-section/features-section.jsx";
import Download from "../../components/blog/download";
import FetchCoverflowCarousel from "../../components/carousel/fetchCoverflowCarousel";
import { collectUserData, connectModalHide } from "../../redux/counterSlice";
import { useDispatch } from "react-redux";

const HomeIndex = () => {
  const dispatch = useDispatch();
  ReactSession.setStoreType("localStorage");
  const [pantheons, setPantheons] = useState([]);
  const [user, setUser] = useState({});

  const fetchPantheons = async (userId) => {
    let getPantheonsUrl = `${process.env.NEXT_PUBLIC_API_ROUTE}/api/cards/getRandomCards/`
    fetch(getPantheonsUrl, {
      method: 'GET',
    })
      .then(async response => {
        const data = await response.json()
        if (data.success) {
          setPantheons(data.data.filter((item) => item.name !== "Uniques"))
          // ReactSession.set("pantheons", data.data)
        }
      })
      .catch(error => console.error('There was an error!', error))

  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const userId = queryParameters.get("id")
    const token = queryParameters.get("token")
    const tempUser = ReactSession.get("user");
    if (userId && token && (!ReactSession.get("token") || (tempUser && tempUser?.isGuest))) {
      const postBody = {
        "userId": userId,
        "token": token
      };

      const requestMetadata = {
        method: 'POST',
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams(postBody)
      };

      fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/confirmLogin/`, requestMetadata)
        .then(res => res.json())
        .then(response => {
          if (tempUser && tempUser.isGuest) {
            dispatch(connectModalHide())
          }
          ReactSession.set("user", response.data.user);
          ReactSession.set("token", response.data.token);
          dispatch(collectUserData(response.data.user));
          fetchPantheons(response.data.user?.pk);
          setUser(response.data.user)
        });
    }

    fetchPantheons("");
  }, [])

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    let redirect = "";
    if (user && user?.pk) {
      //redirect = `/user/${user?.pk}`;
      redirect = `/play`;
    } else if (ReactSession.get("user") && ReactSession.get("user")?.pk) {
      //redirect = `/user/${ReactSession.get("user")?.pk}`;
      redirect = `/play`;
    }

    const claim = queryParameters.get("claim")
    if (claim && redirect && redirect.length) {
      ReactSession.set("claimCode", claim);
    }

    if (redirect && redirect.length) {
      const userId = queryParameters.get("id")
      if (userId) {
        setTimeout(() => {
          window.location.replace(redirect);
        }, 1000);
      } else {
        window.location.replace(redirect);
      }
    }
  }, [user])

  return (
    <>
      <Meta title="Home" />
      <section className="relative">
        <div className="absolute inset-x-0 top-0 bottom-48 opacity-40 overflow-hidden">
          <Image className="w-full h-[70%] md:h-full object-cover"
            src="/images/home/home_hero_bg.webp"
            alt="Home Hero Image"
            width={1920}
            height={1076}
          />
        </div>
        <IndexHero />
        <FetchCoverflowCarousel pantheons={pantheons} />
      </section>
      <FeaturesSection />
      <Download />
    </>
  );
};

export default HomeIndex;
